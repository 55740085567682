import React from "react";
import styles from "./index.module.less";
import ClassNames from "classnames";
import logo from "assets/images/footer/logo@2x.png";
import { ENV, ImageManager } from "utils";

export default function Footer() {
  React.useEffect(() => {
    if (!ENV.isPC) {
      const openScale = (e: any) => {
        ImageManager.open(e.target.src);
      };

      const imgs = document.querySelectorAll(".qrcode");
      imgs.forEach((el) => {
        el.addEventListener("click", openScale);
      });

      return () => {
        imgs.forEach((el) => {
          el.removeEventListener("click", openScale);
        });
      };
    }
  }, []);

  return (
    <div className={styles.container}>
      <div className={styles.top}>
        <div className={styles.logoWrapper}>
          <img src={logo} alt="广州银风科技有限公司" />
        </div>
        <div className={styles.infoWrapper}>
          <div className={styles.infoTitle}>联系我们</div>
          <div>
            <div className={styles.info}>
              <div className={styles.name}>公司地址</div>
              <div>广东省广州市番禺区小谷围街道28号创智大厦1栋5B04</div>
            </div>
            <div className={styles.info}>
              <div className={styles.name}>联系电话</div>
              <div>18011735822</div>
            </div>
            <div className={styles.info}>
              <div className={styles.name}>Email</div>
              <div>1090641256@qq.com</div>
            </div>
          </div>
        </div>
        <div className={styles.qrcodeContainer}>
          <div className={styles.qrcodeWrapper}>
            <span>合作咨询</span>
            <img
              src={require("assets/images/contact/new_manager_qrcode1.png")}
              alt="合作咨询"
              className={ClassNames(styles.qrcode, "qrcode")}
            />
          </div>
          <div className={styles.qrcodeWrapper}>
            <span className={styles.title}>官方微信公众号</span>
            <img
              src={require("assets/images/footer/weixin_qrcode.jpg")}
              alt="官方微信公众号"
              className={ClassNames(styles.qrcode, "qrcode")}
            />
          </div>
        </div>
      </div>
      <div className={styles.bottom}>
        <div className={ClassNames(styles.col, styles.left)}>
          <a href="https://beian.miit.gov.cn">粤ICP备17091856号</a>
        </div>
        <div className={ClassNames(styles.col, styles.right)}>
          Copyright © 2017 - 2024 YinFeng. All Rights Reserved.
        </div>
      </div>
    </div>
  );
}
